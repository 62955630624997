// eslint-disable-next-line import/prefer-default-export
import store from '@/store'

export const formatDate = date => new Date(date).toLocaleString(store.state.user.lang === 'fr' ? 'fr-FR' : 'en-US', {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
})

export const formatDateWithoutHour = date => new Date(date).toLocaleString(store.state.user.lang === 'fr' ? 'fr-FR' : 'en-US', {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})
function addHoursToDate(objDate, intHours) {
  const numberOfMlSeconds = objDate.getTime()
  const addMlSeconds = (intHours * 60) * 60 * 1000
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds)

  return newDateObj
}

export const formatStartDate = date => {
  const mydate = new Date(date)

  return addHoursToDate(mydate, -1).toLocaleString(store.state.user.lang === 'fr' ? 'fr-FR' : 'en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
}
