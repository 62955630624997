<template>
  <div>
    <v-skeleton-loader
      v-show="isDialogVisible"
      boilerplate
      class="mx-auto"
      elevation="2"
      max-width="600"
      type="article"
    ></v-skeleton-loader>
    <v-card v-show="!isDialogVisible">
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            Statut
          </v-card-title>
          <v-card-subtitle>Historique du statut du paiement</v-card-subtitle>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiClockStart }}
          </v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <v-timeline
          dense
          class="timeline-custom-dense timeline-custom-dots"
        >
          <!-- Timeline Item: Flight -->
          <v-timeline-item
            v-for="paymentStatus in $store.getters['paymentStatus/paymentStatuses']"
            :key="paymentStatus.id"
            small
            color="transparent"
          >
            <template #icon>
              <v-avatar
                :color="paymentStatus.status.color"
                class="v-avatar-light-bg primary--text"
                size="40"
              >
                <v-icon
                  size="24"
                  :color="paymentStatus.status.color"
                >
                  {{ paymentStatus.is_active === true ? icons.mdiCashCheck : icons.mdiMinusCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <!-- Header -->
            <div class="d-flex justify-space-between">
              <p class="mb-1 text--primary font-weight-semibold">
                <v-chip
                  small
                  label
                  :color="paymentStatus.status.color"
                >
                  {{ paymentStatus.status.label }}
                </v-chip>
              </p>
            </div>

            <!-- Content -->

            <p>{{ formattingDate(paymentStatus.created_at) }}</p>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import {
  mdiFilePdf, mdiArrowRight, mdiMessageTextOutline, mdiPhone, mdiCashCheck, mdiMinusCircleOutline, mdiClockStart,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import { mapGetters, mapState } from 'vuex'
import { onMounted, ref, computed } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'

export default {
  name: 'PaymentStatus',

  props: {
    payment_id: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const isDialogVisible = ref(false)

    const item = computed({
      get: () => props.payment_id,
      set: value => emit('update:object', value),
    })

    const getList = (page = 1) => {
      if (item.value !== null) {
        store.state.paymentStatus.field.payment_id = item.value
        isDialogVisible.value = true
        store.dispatch('paymentStatus/getPaymentStatusList', { page, field: store.state.paymentStatus.field }).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
        })
      }
    }

    const formattingDate = date => formatDate(date)

    onMounted(() => {
      setTimeout(() => {
        getList(store.getters['paymentStatus/current_page'])
      }, 1000)
    })

    return {
      item,
      isDialogVisible,
      formattingDate,
      icons: {
        mdiFilePdf,
        mdiArrowRight,
        mdiMessageTextOutline,
        mdiPhone,
        mdiCashCheck,
        mdiMinusCircleOutline,
        mdiClockStart,
      },
    }
  },
}
</script>

<style scoped>

</style>
